<template>
	<div class="about-company">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="about-main-box">
			<!-- 导航栏 -->
			<div class="crumbs">
				<div class="cru-logo">
					<img src="../../assets/images/home/daohang_logo.png" alt="">
				</div>
				<el-breadcrumb separator=">">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>公司概况</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="all-introduce">
				<div class="l-int-box">
					<div class="int-title">魔盒文化传媒有限公司</div>
					<div class="int-line"></div>
					<div class="int-content" v-html="company_info.about"></div>
				</div>
				<div class="r-int-photo">
					<div class="yellow-box"></div>
					<div class="r-company-photo">
						<img src="../../assets/images/header-nav/gygs_logo.png" alt="">
					</div>
				</div>
			</div>
			<!-- 屏幕小时 -->
			<div class="all-introduce all-introduce1">
				<div class="int-content" v-html="company_info.about"></div>
			</div>
			<div class="all-civilization">
				<div class="civi-title">
					<span>企业文化</span>
					<div class="hui-title">CORPORATE CULTURE</div>
				</div>
				<div class="civi-module">
					<div class="item-box" data-aos="fade-up" data-aos-delay="100">
						<img src="../../assets/images/header-nav/yuanjing.png" alt="">
						<span class="item-name">愿景</span>
						<div class="item-explain">引领行业发展，全力打造市、省、乃至全国的优秀影视产业。 </div>
					</div>
					<div class="right-box">
						<div class="item-box item-box1" data-aos="fade-up" data-aos-delay="110">
							<img src="../../assets/images/header-nav/tongxiang.png" alt="">
							<span class="item-name">同享成功</span>
						</div>
						<div class="item-box item-box1" data-aos="fade-up" data-aos-delay="115">
							<img src="../../assets/images/header-nav/fazhan.png" alt="">
							<span class="item-name">共同发展</span>
						</div>
						<div class="item-box item-box1" data-aos="fade-up" data-aos-delay="120">
							<img src="../../assets/images/header-nav/zhuanyehua.png" alt="">
							<span class="item-name">专业化精英</span>
						</div>
						<div class="item-box item-box1" data-aos="fade-up" data-aos-delay="125">
							<img src="../../assets/images/header-nav/shuchu.png" alt="">
							<span class="item-name">高效高质的输出</span>
						</div>
					</div>
				</div>
				<div class="target" data-aos="fade-up" data-aos-delay="126" v-html="company_info.culture"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	
	export default {
		name:'About-Company',
		components: {
			BannerOther
		},
		data() {
			return {
				img_url: '',
				chinese_name: '关于公司',
				english_name: 'ABOUT THE COMPANY',
				company_info:{}
			}
		},
		activated() {
			var that = this
			var obj = JSON.parse(localStorage.getItem('company_info'))
			that.company_info = obj;
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.about_image;
			}
		},
		mounted() {
			localStorage.setItem('headerIndex', 1);
		},
		methods:{
		
		},
		// 离开页面事件
		beforeDestroy() {
		},
	}
</script>

<style lang="scss">
	@import '../../assets/css/about-company.scss';
	@import '../../assets/css/public.scss';
</style>
